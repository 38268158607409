import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {QueryFormattingUtility} from "../models/filtration/query-formatting.utility";
import {PageableRequest} from "../models/pageable/pageable.request";
import {InvokableFiltration} from "../models/filtration/filtration";
import {PageableResponse} from "../models/pageable/pageable.response";
import {DistrictResponse} from "../models/district.response";
import {MunicipalityResponse} from "../models/municipality.response";
import {SettlementResponse} from "../models/settlement.response";
import {LocationResponse} from "../models/location/location.response";
import {ValidLocationTokenResponse} from "../models/location/valid-location-token.response";
import {ResidentialAreaResponse} from "../models/residential-area.response";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private queryFormattingUtility: QueryFormattingUtility,
    private http: HttpClient
  ) {
  }

  getDistricts(
    pageable: PageableRequest,
    filtration: InvokableFiltration
  ): Observable<PageableResponse<DistrictResponse>> {
    return this.http.get<PageableResponse<DistrictResponse>>(
      '/api/locations/districts'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }


  getSettlements(
    pageable: PageableRequest | null = null,
    filtration: InvokableFiltration | null = null
  ): Observable<PageableResponse<SettlementResponse>> {
    return this.http.get<PageableResponse<SettlementResponse>>(
      '/api/locations/settlements'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  getMunicipalities(
    pageable: PageableRequest | null = null,
    filtration: InvokableFiltration | null = null
  ): Observable<PageableResponse<MunicipalityResponse>> {
    return this.http.get<PageableResponse<MunicipalityResponse>>(
      '/api/locations/municipalities'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  getResidentailAreas(
    pageable: PageableRequest | null = null,
    filtration: InvokableFiltration | null = null
  ): Observable<PageableResponse<ResidentialAreaResponse>> {
    return this.http.get<PageableResponse<ResidentialAreaResponse>>(
      '/api/locations/residential-areas'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  getLocationToken(locationId: number): Observable<LocationResponse> {
    return this.http.get<LocationResponse>(
      '/api/locations/images/' + locationId
    );
  }

  checkLocationTokenValidity(token: string): Observable<ValidLocationTokenResponse> {
    let params = new HttpParams();
    params = params.set('token', token);

    return this.http.get<ValidLocationTokenResponse>(
      '/api/locations/images/token', {params}
    );
  }
}
