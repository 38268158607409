import {StatisticsResponse} from "../../shared/models/statistics.response";
import {LocationResponse} from "../../shared/models/location/location.response";
import {UserFlatResponse} from "../donations/user-flat.response";

export class ProfileResponse {
  constructor(
    public id: number = 0,
    public password: string = '',
    public firstName: string = '',
    public middleName: string = '',
    public lastName: string = '',
    public email: string = '',
    public address: string = '',
    public addressComment: string = '',
    public registrationDate: Date = new Date(),
    public bopDream: string = '',
    public admin: boolean = false,
    public cityId: number = 1,
    public villageId: number = 1,
    public invitedById: number = 0,
    public userRoles: RoleResponse[] = [],
    public phoneNumbers: PhoneNumberResponse[] = [],
    public invitedBy: UserFlatResponse = new UserFlatResponse(),
    public wantToSell: string = '',
    public wantToBuy: string = '',
    public wantToExchange: string = '',
    public publicProfile: boolean = false,
    public bopSoftware: string = '',
    public trustees: UserFlatResponse[] = [],
    public locations: LocationResponse[] = [],
    public status: number = 1,
    public rating: number = 0,
    public memberUntil: Date = new Date(),
    public statistics: StatisticsResponse = new StatisticsResponse(),
    public selected: boolean = false,
    public pendingInvitedBy: UserFlatResponse | null = null,
    public pendingTrustee: UserFlatResponse | null = null,
    public logoutPeriod: number | null = null,
    public profileNotificationType: number | null = null,
    public givenDonations: ProfileDonationInfoResponse[] = []
  ) {

  }

  static empty() {
    return new ProfileResponse();
  }
}

export class PhoneNumberResponse {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public number: string = ''
  ) {

  }
}

export class Role {
  constructor(
    public id: number = 0,
    public name: string = '',
    public alias: string = ''
  ) {
  }
}

export class RoleResponse {
  constructor(
    public id: number = 0,
    public roleId: number = 0,
    public userId: number = 0,
    public reason: string = ''
  ) {
  }
}

export class ProfileDonationInfoResponse {
  constructor(
    public donationInfo: string = ''
  ) {
  }
}
