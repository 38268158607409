<style>

</style>
<p-sidebar styleClass="md:fixed custom-tiered-menu" [visible]="sidebarVisible" [showCloseIcon]="false" [modal]="false"
           [dismissible]="false">
  <ng-template pTemplate="header">
    <section class="header-wrapper">
      <section class="w-full flex align-items-center justify-content-between">
        <a routerLink="/">
          <img alt="logo" class="w-7rem bop-logo" src="../../../../assets/img/logo/logo-colorful.png">
        </a>
        <span class="text-xs text-primary-500 font-italic sidebar-header-greeting">Здравейте, {{ profileMeta?.name }}
          !</span>
        <p-button class="md:hidden" icon="pi pi-times" [rounded]="true" [text]="true"
                  (click)="this.sidebarVisibility.emit()"></p-button>
      </section>

      @if (sidebarText) {
        <p class="sidebar-text">{{sidebarText}}</p>
      }

      @if (sidebarSearchBar) {
        <p-inputGroup>
          <p-inputGroupAddon>
            <i class="pi pi-search"></i>
          </p-inputGroupAddon>
          <input class="sidebar-search" pInputText placeholder="Търсене..." id="search"/>
        </p-inputGroup>
      }
    </section>
  </ng-template>

  <ng-template pTemplate="content">


  </ng-template>
</p-sidebar>


@if (sidebarVisible) {
  <div class="spacer h-full"></div>

  @if (tieredMenuItems?.length) {
    <div class="tiered-menu" [class.padded-menu]="sidebarText || sidebarSearchBar">
      <p-tieredMenu [model]="tieredMenuItems">
        <ng-template pTemplate="item" let-item let-hasSubmenu="hasSubmenu">
          <div class="py-1 cursor-pointer">
            @if (tieredMenuSelectable) {
              <p-checkbox [value]="item.label"></p-checkbox>
            }
            <span class="item-label ml-2 text-lg">{{ item.label }}</span>
          </div>
        </ng-template>
      </p-tieredMenu>
    </div>
  }

  @if (panelMenuItems?.length) {
    <div class="panel-menu" [class.padded-menu]="sidebarText || sidebarSearchBar">
      <p-panelMenu [model]="panelMenuItems"></p-panelMenu>
    </div>
  }
}
