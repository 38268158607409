import {Component, inject, OnInit} from '@angular/core';
import {LayoutStore} from "../../shared/store/layout/layout.store";
import {selectCurrentUser} from "../profile/store/profile.reducer";
import {MenuItem, MenuItemCommandEvent, PrimeIcons} from "primeng/api";
import {Store} from "@ngrx/store";
import {ProfileState} from "../profile/store/profile.state";
import {RoleResponse} from "../profile/profile.response";
import {AVAILABLE_ROLES} from "../profile/profile.config";
import {Router, RouterOutlet} from "@angular/router";
import {DialogService} from "primeng/dynamicdialog";
import {MarketMissingRoleDialogComponent} from "./market-missing-role-dialog/market-missing-role-dialog.component";
import {MarketBaseComponent} from "./market-base/market-base.component";

@Component({
  selector: 'bop-market',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './market.component.html',
  styleUrl: './market.component.scss'
})
export class MarketComponent extends MarketBaseComponent implements OnInit {
  ngOnInit() {
    this.setBaseLayout();
  }
}
