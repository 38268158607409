import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {FileResponse} from "../models/file.response";
import {CategoryResponse} from "../models/category.response";
import {PageableResponse} from "../models/pageable/pageable.response";
import {Filtration, InvokableFiltration} from "../models/filtration/filtration";
import {PageableRequest} from "../models/pageable/pageable.request";
import {QueryFormattingUtility} from "../models/filtration/query-formatting.utility";

@Injectable({providedIn: 'root'})
export class CategoriesService {
  constructor(private http: HttpClient,
              private queryFormattingUtility: QueryFormattingUtility,
  ) {
  }

  getAll(filtration: InvokableFiltration = Filtration.empty(), page: PageableRequest = PageableRequest.first()): Observable<PageableResponse<CategoryResponse>> {
    return this.http.get<PageableResponse<CategoryResponse>>('api/categories', {params: this.queryFormattingUtility.formatQuery(page, filtration)});
  }
}
