<div class="text-center p-2">
  <p>На Етап 2 тук ще може да въвеждате своите транспортни средства и график
    за периодите, в които те са на разположение. Тези данни са нужни за да
    участвате в коренно нов вид транспортни търгове, които не Ви изстискват за най-ниски цени. Транспортните търгове в
    БОП отчитат БАЛът и качеството на вече изпълнените Ваши доставки за да Ви гарантират нетно над 3 минимални
    работни заплати месечно при 20 курса (трипа). Така БОП Ви подпомага за изграждане на дългосрочно и уважително
    сътрудничество с производителите и клиентите в над 30 направления на България.</p>

  <img src="assets/img/sneak-peaks/upload-transportation.png" alt="">
</div>
