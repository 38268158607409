import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {LayoutStore} from "../../../shared/store/layout/layout.store";
import {MegaMenuItem, MenuItem} from "primeng/api";
import {CategoriesService} from "../../../shared/services/categories.service";
import {CategoryResponse} from "../../../shared/models/category.response";
import {TagStrippedResponse} from "../../../shared/models/tag-stripped.response";
import {ProductMenuElement} from "../../../shared/models/product-menu-element";
import {Filtration} from "../../../shared/models/filtration/filtration";
import {PageableRequest} from "../../../shared/models/pageable/pageable.request";
import {SortEventRequest} from "../../../shared/models/filtration/sort-event.request";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {MarketBaseComponent} from "../market-base/market-base.component";
import {isMobile} from "../../../shared/helpers/device-type.helper";

@Component({
  selector: 'bop-products',
  standalone: true,
  imports: [
    BreadcrumbModule
  ],
  templateUrl: './market-products.component.html',
  styleUrl: './market-products.component.scss'
})
export class MarketProductsComponent extends MarketBaseComponent implements OnInit {
  // TODO: Extract repeating functionality !!! Move sorting to store !!!

  public categoriesService = inject(CategoriesService);

  items: MenuItem[] | undefined;

  home: MenuItem | undefined;

  ngOnInit() {
    this.items = [
      { label: 'Храни' },
      { label: 'Билки' },
      { label: 'Бял равнец' },
      { label: 'пакетирано' },
    ];

    this.layoutStore.setSidebarExpanded(true);
    this.layoutStore.setSidebarPanelMenuItems([]);
    this.layoutStore.setSidebarTieredMenuItems([]);
    this.layoutStore.setSidebarSearchBar(true);

    this.categoriesService.getAll(new Filtration().field('parent.id').isEmpty()).subscribe(res => {
      const items = this.mapCategoriesToMenuItems(res.content);

      if (isMobile()) {
        this.layoutStore.setSidebarPanelMenuItems(items);
        return;
      }

      this.layoutStore.setSidebarTieredMenuItems(items);
    });

  }

  mapCategoriesToMenuItems(categories: ProductMenuElement[]): MenuItem[] {
    return categories
      .map(c => ({label: c.name, items: this.mapCategoriesToMenuItems(c.children?.length ? c.children : c.tags || [])}))
  }
}
