import {SettlementResponse} from "../settlement.response";
import {RoleResponse} from "../../../private/profile/profile.response";
import {FileResponse} from "../file.response";
import {ResidentialAreaResponse} from "../residential-area.response";

export class LocationResponse {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public address: string = '',
    public comment: string = '',
    public coordinates: string = '',
    public locationImage: FileResponse = new FileResponse(),
    public token: string = '',
    public settlement: SettlementResponse = new SettlementResponse(),
    public residentialArea: ResidentialAreaResponse = new ResidentialAreaResponse(),
    public locationRoles: RoleResponse[] = [],
    public isDraft = false
  ) {

  }
}

