import {Routes} from '@angular/router';
import {HomeComponent} from "./private/home/home.component";
import {AuthenticationGuard} from "./shared/guards/authentication.guard";
import {AuthenticationComponent} from "./public/authentication/authentication.component";
import {PublicComponent} from "./public/public.component";
import {PrivateComponent} from "./private/private.component";
import {DonationsComponent} from "./private/donations/donations.component";
import {DonationsReceivedComponent} from "./private/donations/donations-received/donations-received.component";
import {DonationsSentComponent} from "./private/donations/donations-sent/donations-sent.component";
import {ProfileComponent} from "./private/profile/profile.component";
import {ProfileEditComponent} from "./private/profile/profile-edit/profile-edit.component";
import {DonationsInviteComponent} from "./private/donations/donations-invite/donations-invite.component";
import {DonationsWishesComponent} from "./private/donations/donations-wishes/donations-wishes.component";
import {DonationsCreateComponent} from "./private/donations/donations-create/donations-create.component";
import {DonationsTrusteesComponent} from "./private/donations/donations-trustess/donations-trustees.component";
import {AdminComponent} from "./private/admin/admin.component";
import {AdminUsersComponent} from "./private/admin/admin-users/admin-users.component";
import {AdminDonationsComponent} from "./private/admin/admin-donations/admin-donations.component";
import {AdminMailsComponent} from "./private/admin/admin-mails/admin-mails.component";
import {GuestComponent} from "./guest/guest.component";
import {LocationImagesComponent} from "./guest/location-images/location-images.component";
import {MarketComponent} from "./private/market/market.component";
import {
  AdminHomePageSystemMessageComponent
} from "./private/admin/admin-home-page-system-message/admin-home-page-system-message.component";
import {
  AdminHomePageCarouselComponent
} from "./private/admin/admin-home-page-carousel/admin-home-page-carousel.component";
import {MarketCreateComponent} from "./private/market/market-create/market-create.component";
import {MarketSearchComponent} from "./private/market/market-search/market-search.component";
import {
  MarketCreateGoodsComponent
} from "./private/market/market-create/market-create-goods/market-create-goods.component";
import {
  MarketCreateTransportationsComponent
} from "./private/market/market-create/market-create-transportations/market-create-transportations.component";
import {AdminCooperatorsInfoComponent} from "./private/admin/admin-cooperator-info/admin-cooperators-info.component";
import {
  MarketCreateConsultationComponent
} from "./private/market/market-create/market-create-consultation/market-create-consultation.component";
import {
  MarketCreateFoodComponent
} from "./private/market/market-create/market-create-food/market-create-food.component";
import {
  MarketCreateServiceComponent
} from "./private/market/market-create/market-create-service/market-create-service.component";
import {
  MarketCreateTechnologyComponent
} from "./private/market/market-create/market-create-technology/market-create-technology.component";
import {
  MarketCreateUsedComponent
} from "./private/market/market-create/market-create-used/market-create-used.component";
import {
  MarketSearchFoodComponent
} from "./private/market/market-search/market-search-food/market-search-food.component";
import {
  MarketSearchGoodsComponent
} from "./private/market/market-search/market-search-goods/market-search-goods.component";
import {
  MarketSearchServiceComponent
} from "./private/market/market-search/market-search-service/market-search-service.component";
import {
  MarketSearchTechnologyComponent
} from "./private/market/market-search/market-search-technology/market-search-technology.component";
import {
  MarketSearchTransportationsComponent
} from "./private/market/market-search/market-search-transportations/market-search-transportations.component";
import {
  MarketSearchUsedComponent
} from "./private/market/market-search/market-search-used/market-search-used.component";
import {
  MarketSearchConsultationComponent
} from "./private/market/market-search/market-search-consultation/market-search-consultation.component";
import {MarketProductsComponent} from "./private/market/market-products/market-products.component";

export const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'private/home'},
  {
    path: 'public', component: PublicComponent, children: [
      {path: 'authentication', component: AuthenticationComponent}
    ]
  },
  {
    path: 'private', component: PrivateComponent, canActivate: [AuthenticationGuard], children: [
      {path: 'home', component: HomeComponent},
      {
        path: 'profile', component: ProfileComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'edit'},
          {path: 'edit', component: ProfileEditComponent},
          {path: 'edit/light/:token', component: ProfileEditComponent}
        ]
      },
      {
        path: 'donations', component: DonationsComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'received'},
          {path: 'invite', component: DonationsInviteComponent},
          {path: 'create', component: DonationsCreateComponent},
          {path: 'trustees', component: DonationsTrusteesComponent},
          {path: 'received', component: DonationsReceivedComponent},
          {path: 'wishes', component: DonationsWishesComponent},
          {path: 'sent', component: DonationsSentComponent}
        ]
      },
      {
        path: 'market', component: MarketComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'search'},
          {
            path: 'create', component: MarketCreateComponent, children: [
              {path: '', pathMatch: 'full', redirectTo: 'food'},
              {path: 'food', component: MarketCreateFoodComponent},
              {path: 'goods', component: MarketCreateGoodsComponent},
              {path: 'service', component: MarketCreateServiceComponent},
              {path: 'technology', component: MarketCreateTechnologyComponent},
              {path: 'transportations', component: MarketCreateTransportationsComponent},
              {path: 'used', component: MarketCreateUsedComponent},
              {path: 'consultations', component: MarketCreateConsultationComponent}
            ]
          },
          {path: 'search', component: MarketSearchComponent, children: [
              {path: '', pathMatch: 'full', redirectTo: 'food'},
              {path: 'food', component: MarketSearchFoodComponent},
              {path: 'goods', component: MarketSearchGoodsComponent},
              {path: 'service', component: MarketSearchServiceComponent},
              {path: 'technology', component: MarketSearchTechnologyComponent},
              {path: 'transportations', component: MarketSearchTransportationsComponent},
              {path: 'used', component: MarketSearchUsedComponent},
              {path: 'consultations', component: MarketSearchConsultationComponent}
            ]
          },
          {
            path: 'products', component: MarketProductsComponent
          }
        ]
      },
      {
        path: 'admin', component: AdminComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'users'},
          {path: 'users', component: AdminUsersComponent},
          {path: 'donations', component: AdminDonationsComponent},
          {path: 'mails', component: AdminMailsComponent},
          {path: 'home-page/system-message', component: AdminHomePageSystemMessageComponent},
          {path: 'home-page/carousel', component: AdminHomePageCarouselComponent},
          {path: 'contact-info', component: AdminCooperatorsInfoComponent}
        ]
      },
    ]
  },
  {
    path: 'guest', component: GuestComponent, children: [
      {path: 'addresses/images/:token', component: LocationImagesComponent}
    ]
  },
  {
    path: 'profile/edit/:token', redirectTo: 'private/profile/edit/light/:token'
  },
];
