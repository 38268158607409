import {Component, EventEmitter, HostListener, inject, Input, Output} from '@angular/core';
import {MegaMenuItem, MenuItem, MenuItemCommandEvent, PrimeIcons} from "primeng/api";
import {SidebarModule} from "primeng/sidebar";
import {MenubarModule} from "primeng/menubar";
import {PanelMenuModule} from "primeng/panelmenu";
import {AsyncPipe} from "@angular/common";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import {StatisticsResponse} from "../../models/statistics.response";
import {MegaMenuModule} from "primeng/megamenu";
import {TieredMenuModule} from "primeng/tieredmenu";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {CheckboxModule} from "primeng/checkbox";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {InputNumberModule} from "primeng/inputnumber";
import {PaginatorModule} from "primeng/paginator";
import {ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {isMobile} from "../../helpers/device-type.helper";

@Component({
  selector: 'bop-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    MenubarModule,
    PanelMenuModule,
    AsyncPipe,
    MenuModule,
    ButtonModule,
    MegaMenuModule,
    TieredMenuModule,
    OverlayPanelModule,
    CheckboxModule,
    InputGroupAddonModule,
    InputGroupModule,
    InputNumberModule,
    PaginatorModule,
    ReactiveFormsModule,
    InputTextModule
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: 'sidebar.component.scss'
})
export class SidebarComponent {
  @Output()
  public sidebarVisibility: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public panelMenuItems: MenuItem[] | undefined;

  @Input()
  public tieredMenuItems: MenuItem[] | undefined;

  @Input()
  public sidebarVisible = false;

  @Input()
  public tieredMenuSelectable = false;

  @Input()
  public sidebarSearchBar = false;

  @Input()
  public sidebarText = '';

  @Input()
  public profileMeta: StatisticsResponse | undefined;

  isMobile = false;
  isFixed = false;

  constructor() {
    this.checkIfMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const scrollLeft = window.scrollX; // Check global horizontal scroll
    this.isFixed = scrollLeft > 300;
  }

  checkIfMobile() {
    this.isMobile = isMobile();
  }

  closeSidebar() {
    if (this.isMobile) {
      this.sidebarVisibility.emit(false);
    }
  }

  public onMenuItemClick(event: MenuItemCommandEvent): void {
    if (event.item && (!event.item.items || event.item.items.length === 0)) {
      this.closeSidebar();
    }
  }

  public attachCommand(items: MenuItem[] | undefined): void {
    if (items) {
      items.forEach(item => {
        item.command = (event: MenuItemCommandEvent) => this.onMenuItemClick(event);
        if (item.items) {
          this.attachCommand(item.items);
        }
      });
    }
  }




  ngOnChanges(): void {
    this.attachCommand(this.panelMenuItems);
  }

}
