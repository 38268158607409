import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {Filtration} from "../../../shared/models/filtration/filtration";
import {ProductMenuElement} from "../../../shared/models/product-menu-element";
import {MenuItem} from "primeng/api";
import {LayoutStore} from "../../../shared/store/layout/layout.store";
import {CategoriesService} from "../../../shared/services/categories.service";
import {MarketBaseComponent} from "../market-base/market-base.component";

@Component({
  selector: 'bop-market-create',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './market-create.component.html',
  styleUrl: './market-create.component.scss'
})
export class MarketCreateComponent extends MarketBaseComponent implements OnInit {
  // TODO: Extract repeating functionality !!! Move sorting to store !!!
  public categoriesService = inject(CategoriesService);

  items: MenuItem[] | undefined;

  home: MenuItem | undefined;

  ngOnInit() {
    this.items = [
      { label: 'Храни' },
      { label: 'Билки' },
      { label: 'Бял равнец' },
      { label: 'пакетирано' },
    ];

    this.layoutStore.resetSidebar();
    this.layoutStore.setSidebarExpanded(true);
    this.layoutStore.setSidebarText('Въведете вашите български:');
    this.layoutStore.setTieredMenuSelectable(true);

    this.categoriesService.getAll(new Filtration().field('parent.id').isEmpty()).subscribe(res => {
      const items = this.mapCategoriesToMenuItems(res.content);

      this.layoutStore.setSidebarTieredMenuItems(items);
    });

  }

  mapCategoriesToMenuItems(categories: ProductMenuElement[]): MenuItem[] {
    return categories
      .map(c => ({label: c.name}));
  }
}
